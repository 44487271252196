import {useEffect} from "react"
import ContentBlock from "../../components/UI/ContentBlock"

const APIScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — API документация'
	}, [])

	return (
		<ContentBlock>
			<elements-api
				apiDescriptionUrl="https://raw.githubusercontent.com/stoplightio/Public-APIs/master/reference/zoom/openapi.yaml"
				router="hash"
			/>
		</ContentBlock>

	)
}

export default APIScreen
