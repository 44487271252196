import {Route, Routes, useLocation} from "react-router-dom"
import styled from "styled-components"
import {Layout} from 'antd'
import HomeScreen from "./Home/HomeScreen"
import Header from "./Layout/Header"
import Footer from "./Layout/Footer"
import MobileAppScreen from "./MobileApp/MobileAppScreen"
import RoutingScreen from "./Routing/RoutingScreen"
import {useEffect} from "react"
import MonitoringScreen from "./Monitoring/MonitoringScreen"
import PriceScreen from "./Price/PriceScreen"
import ContactScreen from "./Contact/ContactScreen"
import ServicesScreen from "./Services/ServicesScreen"
import PrivacyScreen from "./Privacy/PrivacyScreen"
import APIScreen from "./API/APIScreen"

const AppNavigation = () => {

	const location = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<Layout>
			<Wrapper>
				<Header/>
				<Content>
					<Routes>
						<Route path="/*" element={<HomeScreen/>}/>
						<Route path="/mobile-app" element={<MobileAppScreen/>}/>
						<Route path="/routing" element={<RoutingScreen/>}/>
						<Route path="/monitoring" element={<MonitoringScreen/>}/>
						{/*<Route path="/services" element={<ServicesScreen/>}/>*/}
						<Route path="/price" element={<PriceScreen/>}/>
						<Route path="/contact" element={<ContactScreen/>}/>
						<Route path="/docs/privacy" element={<PrivacyScreen/>}/>
						<Route path="/api" element={<APIScreen/>}/>
					</Routes>
				</Content>
				<Footer/>
			</Wrapper>
		</Layout>
	)
}

export default AppNavigation

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background: ${props => props.theme.colorBgContainer};
    color: ${props => props.theme.colorText};
`
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
`
